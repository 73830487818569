@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.wrapper {
  font-family: "Poppins", sans-serif;
  margin-top: 14rem;
  margin-left: 6rem;
  margin-bottom: 8rem;
}

.job_card {
  width: 22rem;
  /* box-shadow: 0px 1px 8px rgb(0 0 0 / 25%); */
  box-shadow: 0 0 29px -3px #b3b2b2;
  border-radius: 18px !important;
  background-color: white !important;
  border: none !important;
  margin: 1rem 1rem 1rem 0;
}

.new-jobs {
  list-style: none;
  display: flex;
  flex-wrap: inherit;
  padding: 0;
  justify-content: space-between;
}

.job_postings {
  display: flex;
  flex-wrap: wrap;
}

.card-body {
  padding: 1.5rem !important;
}

.job-header {
  margin-bottom: 20px;
}

.bodycard {
  height: 360px;
}

.job-header > h1 {
  font-size: 2rem;
}
.job-header > h1,
.card-title,
.card-subtitle {
  color: #4e4e4e;
  font-weight: 600;
}

.btn-actions {
  text-align: center;
}

.apply-button {
  padding: 5px 20px;
  text-transform: uppercase;
  background: #e36456;
  border: #e36456;
  border-radius: 8px;
  color: #fff;
  letter-spacing: 2px;
}

.apply-button:hover {
  background-color: #e7948b !important;
}
.wrapper {
  margin-bottom: 8rem;
}

@media screen and (max-width: 1200px) {
  .job_card {
    width: 16rem;
  }

  .job-header > h1 {
    font-size: 1.8rem;
  }

  .wrapper {
    margin-top: 8rem;
    margin-left: 3rem;
  }

  .description-div {
    font-size: 17px;
  }

  .bodycard {
    height: 335px;
  }
}

@media screen and (max-width: 992px) {
  .job_card {
    width: 19rem;
  }

  .job-header > h1 {
    font-size: 1.5rem;
  }

  .wrapper {
    margin: 6rem 2rem auto 2rem;
  }

  .description-div {
    font-size: 14px;
  }

  .bodycard {
    height: 327px;
  }
}

@media screen and (max-width: 768px) {
  .job_card {
    width: 25rem;
  }

  .card-title {
    font-size: 18px;
  }

  .job-header > h1 {
    font-size: 1.3rem;
  }

  .wrapper {
    margin: 4rem 2rem 0 2rem;
    padding-bottom: 5rem;
  }

  .apply-button {
    font-size: 11px !important;
  }

  .description-div {
    font-size: 12px;
  }

  .bodycard {
    height: 320px;
  }

  .card-subtitle {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .job_card {
    width: 20rem;
    margin: 10px auto;
  }

  .card-title {
    font-size: 18px;
  }

  .job-header > h1 {
    font-size: 1.2rem;
  }

  .apply-button {
    font-size: 10px !important;
  }

  .description-div {
    font-size: 12px;
  }

  .bodycard {
    height: 318px;
  }

  .card-subtitle {
    font-size: 12px;
  }

  .card-title {
    font-size: 16px;
  }
}

@media screen and (max-width: 560px) {
  .new-jobs {
    justify-content: center;
  }

  .card-title {
    font-size: 18px;
  }

  .job-header > h1 {
    font-size: 1.2rem;
  }

  .apply-button {
    font-size: 10px !important;
  }

  .description-div {
    font-size: 10px;
  }

  .bodycard {
    height: 310px;
  }

  .card-subtitle {
    font-size: 12px;
  }

  .card-title {
    font-size: 14px;
  }
}

@media screen and (min-width: 992px) {
  .new-jobs {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1350px) {
  .job_card {
    width: 21rem;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1450px) {
  .job_card {
    width: 18rem;
  }

  .bodycard {
    height: 360px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .job_card {
    width: 20rem;
  }
}

@media screen and (min-width: 992px) and (max-width: 1100px) {
  .job_card {
    width: 28rem;
  }

  .bodycard {
    height: 336px;
  }
}

@media screen and (min-width: 767px) and (max-width: 991px) {
  .job_card {
    width: 20rem;
  }

  .bodycard {
    height: 343px;
  }
}

@media screen and (min-width: 600px) and (max-width: 767px) {
}

@media screen and (max-width: 600px) {
  .job_card {
    /* width: 100%; */
    margin: 10px auto;
  }

  .bodycard {
    height: 341px;
  }
}
