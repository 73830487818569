@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600;700&display=swap");

.background-footer {
  background-image: url("../images/world.png");
  background-position: center;
  background-repeat: no-repeat;
}

.footer {
  background-color: #222;
  border-top: 4px solid #555;
  color: #ccc;
}

.full-height p {
  font-size: 15px;
  margin: 10px 0;
  font-weight: 400;
}

.footer-container {
  margin: 40px auto;
  margin-bottom: 0;
  margin-top: 84px;
}

.footer-row {
  margin: 30px auto;
}

.footer-wid {
  text-align: left;
  margin-top: 50px;
  margin-bottom: 50px;
}

.social-icons {
  padding: 0;
}

ul {
  padding: 0 !important;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none !important;
}

.footer a {
  color: #666;
}
.full-height {
  height: 100%;
}

.full-height p {
  font-size: 15px;
  margin: 10px 0;
  font-weight: 400;
  color: #ccc;
  font-family: "Karla", sans-serif;
}

.padZero {
  padding: 0;
}

.section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer-wid h1 {
  margin-bottom: 20px;
}

.footer-wid h1 {
  margin-bottom: 20px;
}

h1 {
  font-size: 26px !important;
  line-height: 30px;
  color: #505050;
  font-family: "Karla", sans-serif;
  font-weight: 600;
}

.footer-widget {
  margin-bottom: 15px;
}

.move-left {
  margin-left: 8.33333333%;
}

.row-content {
  display: flex;
}

.social-widget ul.social-icons li {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
}
.social-widget ul.social-icons li a {
  font-size: 1.4em;
  width: 36px;
  height: 36px;
  color: #fff;
  line-height: 36px;
  text-align: center;
  display: block;
  border-radius: 2px;
  cursor: pointer;
}

.facebook:hover {
  background: #0d88f0;
}

.twitter:hover {
  background: #1c9cea;
}

.linkedIn:hover {
  background: #2b65a1;
}

.copyright-section {
  padding: 15px 0;
  font-size: 11px;
  letter-spacing: 0.5px;
  border-top: 1px solid rgba(255, 255, 255, 0.06);
  text-transform: uppercase;
}

.copyright-section ul li {
  font-size: 15px;
  margin: 10px;
  font-weight: 400;
  color: #ccc;
  cursor: pointer;
  font-family: "Karla", sans-serif;
}

.footer-nav {
  float: right;
  text-transform: uppercase;
}
ul.footer-nav li:first-child {
  margin-left: 0;
}

ul.footer-nav li {
  display: inline-block;
  margin-left: 15px;
}

/* media queries */

@media screen and (max-width: 992px) {
  h1 {
    font-size: 24px !important;
  }

  .full-height p {
    font-size: 14px;
  }

  .copyright-section ul li {
    font-size: 14px;
  }

  .social-widget ul.social-icons li a {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 768px) {
  h1 {
    font-size: 21px !important;
  }

  .row-content {
    flex-direction: column;
  }
  .company-block {
    margin-left: 8.33333333%;
  }
  .full-height p {
    font-size: 13px;
  }

  .copyright-section ul li {
    font-size: 13px;
  }

  .copyright-section {
    text-align: center;
  }

  .social-widget ul.social-icons li a {
    font-size: 1em;
  }

  .footer-col {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  h1 {
    font-size: 18px !important;
  }

  .full-height p {
    font-size: 12px;
  }

  .copyright-section ul li {
    font-size: 12px;
  }

  .social-widget ul.social-icons li a {
    font-size: 15px;
  }
}
