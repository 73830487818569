@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

body {
  font-family: "Montserrat";
  background-color: white;
}

.box {
  display: flex;
  justify-content: space-between;
  padding: 5rem 5rem 0 5rem;
}

.nav {
  color: #e36456;
  text-decoration: none;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.box > button {
  background-color: #e36456;
  border-color: #e36456;
  letter-spacing: 1px;
}

.box > nav {
  background-color: #e36456;
}

.box > button {
  background-color: #e36456;
  border-color: #e36456;
  letter-spacing: 1px;
}

.nav:hover {
  color: #e36456 !important;
}

.applyJob-button {
  padding: 5px 20px;
  text-transform: uppercase;
  background: #e36456;
  border: #e36456;
  border-radius: 8px;
  color: #fff;
  letter-spacing: 2px;
}



.jobTitle {
  margin-top: 10px;
  margin-left: 5rem;
}

.jobTitle > h1 {
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.jobTitle > p {
  font-weight: 400;
}

.header {
  margin: 30px 0 2rem 5rem;
  font-size: 1.2rem;
}

.header-main > p {
  margin: 0;
  font-size: 1.2rem;
  padding: 0;
  width: 90%;
}
.header > p {
  margin: 0;
  padding: 0;
  width: 90%;
}

.skills-para {
  margin-bottom: 18px !important;
}

.title {
  font-weight: 700;
  font-size: 1.5rem;
}

.skills-list {
  text-transform: lowercase;
  list-style-type: disc;
  margin-left: 25px;
  margin-bottom: 5rem;
}

.header-main {
  margin: 0 0 4rem 5rem;
}

.paragraph {
  font-size: 13px;
  font-weight: 400;
  padding-right: 12rem;
  letter-spacing: 1px;
}

.backarrow {
  font-size: 1.5rem;
}

.jobPage-logo > img {
  width: 10rem;
  margin-left: 5.5rem;
  padding-top: 2.5rem;
}

.job_details {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../images/topSection-new.png");
  height: 35rem;
}
/* Media Queries */

@media screen and (min-width: 320px) and (max-width: 992px) {
  .job_details {
    background-image: none;
    height: auto;
  }

  .outermost-div {
    background-color: #f8f8f8;
    padding: 20px;
    padding-left: 2rem;
  }

  .box {
    padding-left: 0;
    padding-right: 0;
  }

  .jobTitle {
    margin-left: 0;
  }

  .header-main {
    margin-left: 0;
  }

  .header {
    margin-left: 0;
  }

  .jobPage-logo > img {
    margin-left: 0;
    padding-top: 0;
  }
}

@media screen and (max-width: 992px) {
  .backarrow {
    font-size: 1.3rem;
  }

  .nav {
    font-size: 1.2rem;
  }
  .jobTitle > h1 {
    font-size: 1.8rem;
  }

  .header-main > h2 {
    font-size: 1.2rem;
  }
  .header-main {
    margin-top: 8rem;
  }

  .header-main > p {
    font-size: 1rem;
  }
  .header {
    font-size: 1rem;
  }

  .applyJob-button {
    padding: 3px 17px;
    font-size: 15px;
  }

  .jobPage-logo > img {
    width: 8rem;
  }
}
@media screen and (max-width: 768px) {
  .backarrow {
    font-size: 1.2rem;
  }

  .nav {
    font-size: 15px;
  }
  .jobTitle > h1 {
    font-size: 1.5rem;
  }

  .header-main > h2 {
    font-size: 1rem;
  }

  .header-main {
    margin-top: 6rem;
  }
  .header-main > p {
    font-size: 14px;
  }
  .header {
    font-size: 14px;
  }

  .applyJob-button {
    padding: 3px 15px;
    font-size: 14px;
  }

  .jobPage-logo > img {
    width: 7rem;
  }

  .title {
    font-size: 1rem;
  }
}
@media screen and (max-width: 600px) {
  .backarrow {
    font-size: 12px;
  }

  .nav {
    font-size: 14px;
  }

  .jobTitle > h1 {
    font-size: 1.2rem;
  }

  .header-main > h2 {
    font-size: 14px;
  }

  .header-main {
    margin-top: 4rem;
  }

  .header-main > p {
    font-size: 12px;
  }
  .header {
    font-size: 12px;
  }
  .applyJob-button {
    padding: 3px 12px;
    font-size: 12px;
  }
  .jobPage-logo > img {
    width: 5rem;
  }
}
