@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

body {
  font-family: "Poppins";
  background-color: white;
}

.outer_card {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../images/topSection-new.png");
}

.intro_card {
  display: flex;
  justify-content: space-between;
}

.intro_div {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.intro_div > h1 {
  color: #4e4e4e;
  font-weight: 600;
}

.intro_div > p {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 37px;
}

.apply-button:hover {
  background-color: #da5547 !important;
  color: #fff !important;
}


.img-logo > img {
  width: 10rem;
  padding-top: 2rem;
}

.img_div > img {
  width: 40rem;
  position: absolute;
  right: 0;
}

.para {
  font-size: 1.2rem;
  margin-top: 5rem;
}

.apply-button {
  padding: 5px 20px;
  text-transform: uppercase;
  background: #e36456;
  border: #e36456;
  border-radius: 8px;
  color: #fff;
  letter-spacing: 2px;
}
.intro_div {
  height: 28rem;
}

@media screen and (min-width: 1600px) {
  .img_div > img {
    position: relative;
  }
}
@media screen and (max-width: 1200px) {
  .img-logo > img {
    width: 9rem;
  }

  .para {
    font-size: 18px;
  }

  .intro_div > h1 {
    font-size: 2rem;
  }

  .intro_div > p {
    font-size: 14px;
  }

  .img_div > img {
    width: 35rem;
  }

  .apply-button {
    font-size: 13px;
  }
}

@media screen and (max-width: 992px) {
  .img-logo > img {
    width: 8rem;
  }

  .intro_div {
    height: 20rem;
  }

  .para {
    font-size: 16px;
  }

  .intro_div > h1 {
    font-size: 1.8rem;
  }

  .intro_div > p {
    font-size: 12px;
  }

  .img_div > img {
    width: 26rem;
  }

  .apply-button {
    font-size: 13px;
  }
  .intro_div {
    height: 20rem;
  }
}

@media screen and (max-width: 768px) {
  .img-logo > img {
    width: 7rem;
  }

  .para {
    font-size: 14px;
    margin-top: 0;
  }

  .intro_div > h1 {
    font-size: 1.5rem;
  }

  .intro_div > p {
    font-size: 10px;
  }

  .img_div > img {
    width: 20rem;
  }

  .apply-button {
    font-size: 8px;
  }
}

@media screen and (max-width: 600px) {
  .img-logo > img {
    width: 5rem;
  }

  .intro_div {
    height: 20rem;
    width: 100%;
    align-items: center;
  }

  .para {
    font-size: 12px;
    margin-top: 0;
    text-align: center;
    padding: 0 30px;
  }

  .intro_div > h1 {
    font-size: 1rem !important;
  }

  .intro_div > p {
    font-size: 8px;
  }

  .img_div > img {
    width: 0rem;
  }

  .apply-button {
    font-size: 7px;
  }

  .intro_card {
    background-image: none;
  }

  .job-header > h1 {
    text-align: center;
  }
}
