@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

.job-form {
  margin: 3rem auto;
  padding: 5rem;
  width: 58%;
  background: #f6f6f6;
  font-family: "Poppins";
}

.form-header > h2 {
  margin-bottom: 22px;
}

.sign-in {
  padding: 5px 20px;
  background: #1e7fbf;
  border: #1e7fbf;
  border-radius: 8px;
  color: #fff;
}
.application-form {
  padding: 2rem;
  padding-left: 0;
  padding-right: 0;
  width: 60%;
  margin: 0 auto;
  margin-right: 0;
}
.required {
  font-size: 1.2rem;
  font-weight: 100;
  float: right;
}

.horizontal-line {
  margin: 30px 0px 0px;
}

.asterisk {
  font-size: 9px;
  color: #d10202;
}
.col-form-label {
  font-size: 1.2rem;
}

.app-btn {
  text-align: center;
}

.submit-button {
  padding: 5px 20px;
  text-transform: uppercase;
  background: #e36456;
  border: #e36456;
  border-radius: 8px;
  color: #fff;
  letter-spacing: 2px;
}

.form-control .invalid {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
  margin-top: 10px;
  font-size: 14px;
}

.col-form-label {
  text-align: end;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Media Queries */
@media screen and (min-width: 993px) {
  .application-form {
    width: 90%;
  }
}
@media screen and (min-width: 320px) and (max-width: 992px) {
  .job-form {
    margin: 0;
    padding: 0px;
    width: 100%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .job-form {
    margin: auto;
    margin-bottom: 3rem;
    width: 80%;
  }
}

@media screen and (max-width: 992px) {
  .form-header > h2 {
    font-size: 1.2rem;
  }

  .required {
    font-size: 1rem;
  }

  .application-form {
    width: 100%;
  }
  .col-form-label {
    font-size: 1rem;
    padding-left: 5px;
  }

  .form-control {
    font-size: 16px;
  }
  #formFile {
    font-size: 16px;
  }

  .asterisk {
    font-size: 8px;
  }

  .submit-button {
    padding: 4px 18px;
    font-size: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .form-header > h2 {
    font-size: 1rem;
  }
  .required {
    font-size: 14px;
  }

  .col-form-label {
    font-size: 14px;
  }

  #formFile {
    font-size: 14px;
  }

  .form-control {
    font-size: 14px;
  }

  .asterisk {
    font-size: 6px;
  }

  .submit-button {
    padding: 3px 17px;
    font-size: 15px;
  }
}
@media screen and (max-width: 600px) {
  .form-header > h2 {
    font-size: 14px;
  }

  .required {
    font-size: 12px;
  }

  .col-form-label {
    font-size: 12px;
  }

  .application-form {
    padding-left: 0;
    padding-right: 0;
  }
  .form-control {
    font-size: 12 px;
  }

  #formFile {
    font-size: 12px;
  }

  .asterisk {
    font-size: 5px;
  }

  .submit-button {
    padding: 3px 16px;
    font-size: 14px;
  }
}
